'use strict';

export default function() {
  return {
    scope: {
      file: '='
    },
    link: function(scope, el, attrs) {
      el.bind('change', function(event) {
        var file = event.target.files.length > 1 ? event.target.files : event.target.files[0];
        scope.file = file ? file : undefined;
        scope.$apply();
      });
    }
  };
}