'use strict';

export default function () {
  return {
    controller: function($scope, $window) {
      'ngInject';

      $scope.height = 1;

      $window.addEventListener("message", _receiveMessage, false);

      function _receiveMessage(event) {
        if (event.data.type === 'height') {
          $scope.height = event.data.height;
          $scope.$apply();
        }
      }
    },
    template: require('./iframe.html')
  };
};
