'use strict';

//import angular from 'angular';

export default function () {
  return {
    restrict: "A",
    scope: {
      filenameCallback: "&"
    },
    link: function (scope, elem) {
      var child = null;

      elem.bind('dragover', function (evt) {
        evt.preventDefault();
        if(evt.originalEvent)
          evt = evt.originalEvent;
        evt.dataTransfer.dropEffect = "copy";

        if(child === null) {
          child = angular.element('<div class="filehoveroverlay"><h1>DropFiles</hi></div>');
          elem.append(child);
        }

        child.bind('dragleave', function () {
          child.remove();
          child = null;
        }).bind('drop', function(dropevt) {
          if(dropevt.originalEvent)
            dropevt = dropevt.originalEvent;

          if (child) {
            dropevt.stopPropagation();
            dropevt.preventDefault();

            var files = dropevt.dataTransfer.files;
            scope.filenameCallback({ files: files });
            child.remove();
            child = null;
          }
        }).bind('dragover', function (dragevt) {
          if(dropevt.originalEvent)
            dropevt = dropevt.originalEvent;
          dragevt.preventDefault();
          dragevt.dataTransfer.dropEffect = "copy";
        });
      });
    }
  };
};
