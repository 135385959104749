'use strict';

export default function () {
  return {
    scope: {
      elements: '=',
      tabdepth: '=',
      editor: '=',
      pageid: '='
    },
    controller: function ($scope) {
      'ngInject';
      $scope.mouseClick = function (event, element) {
        event.stopPropagation();
        $scope.$emit('ElementSelected', element, $scope.elements);
      };


      $scope.GetStyle = function GetStyle(element) {
        var style = "";
        _.each(element.data.cssitems, function(item) {
          if(item.breakpoint) {
            style += `@media (max-width: ${item.breakpoint}px) {`;
          }
          style += `#Element-${element.element_id} ${item.class} { ${item.css.replace(/\n/g, " ")} }`;
          if(item.breakpoint) {
            style += `}`;
          }
        });
        return style;
      };

    },
    template: require('./elementsDisplay.html')
  };
};
