'use strict';

import _ from 'lodash';

export default function () {
  return {
    controller: function ($scope) {
      'ngInject';

      $scope.GetStyle = function GetStyle(element) {
        var style = "";
        _.each(element.data.cssitems, function(item) {
          style += `#Element-${element.element_id} ${item.class} { ${item.css.replace(/\n/g, " ")} }`;
        });
        return style;
      };
    },
    template: require('./columns.html')
  };
};
