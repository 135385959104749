'use strict';

export default function routes($stateProvider) {
  'ngInject';

  $stateProvider

    .state('sgcontenteditorAdmin', {
      url: '/pages/admin',
      params: { error: null },
      template: require('./sgcontenteditorAdmin/listPages.html'),
      controller: 'sgcontenteditorEditorListPagesCtrl',
      controllerAs: 'vm'
    })


    .state('sgcontenteditorNewPage', {
      //parent: 'sgcontenteditorAdmin',
      url: '/pages/admin/new',
      template: require('./sgcontenteditorAdmin/editPage.html'),
      controller: 'sgcontenteditorEditorEditPageCtrl',
      controllerAs: 'vm',
      params: {
        copyPage: null
      },
      resolve: {
        page: function(sgcontenteditorService, $stateParams) {
          var page = $stateParams.copyPage;
          if(page == null) {
            page = sgcontenteditorService.newPage();
          } else {
            page.page_id = 0;
            page.pastVersions = [];
            page.published = false;
            page.prevPublished = false;
            page.deleted = false;
          }
          
          return page;
        }
      }
    })

    .state('sgcontenteditorEditPage', {
      url: '/pages/admin/edit/{page_id}',
      template: require('./sgcontenteditorAdmin/editPage.html'),
      controller: 'sgcontenteditorEditorEditPageCtrl',
      controllerAs: 'vm',
      resolve: {
        page: function(sgcontenteditorService, $stateParams) {
          var page = sgcontenteditorService.getPageAdmin($stateParams.page_id);
          return page;
        }
      }
    })

    .state('sgcontenteditorListPages', {
      url: '/pages',
      params: { error: null, noAccess: false, page: null },
      template: require('./sgcontenteditorPage/listPages.html'),
      controller: 'sgcontenteditorListPagesCtrl',
      controllerAs: 'vm'
    })

    .state('sgcontenteditorViewPage', {
      parent: 'sgcontenteditorListPages',
      url: '/{page_id}/{page_name}/{tab1}/{tab2}/{tab3}/{tab4}/{tab5}/{tab6}/{tab7}/{tab8}',
      params: {
        tab1: { squash: true, value: null },
        tab2: { squash: true, value: null },
        tab3: { squash: true, value: null },
        tab4: { squash: true, value: null },
        tab5: { squash: true, value: null },
        tab6: { squash: true, value: null },
        tab7: { squash: true, value: null },
        tab8: { squash: true, value: null },
        page_name: { squash: true, value: null },
        pageInfo: null
      },
      template: require('./sgcontenteditorPage/viewPage.html'),
      controller: 'sgcontenteditorViewPageCtrl',
      controllerAs: 'vm',
      resolve: {
        page: function(sgcontenteditorService, $stateParams) {
          var page = sgcontenteditorService.getPage($stateParams.page_id);
          return page;
        }
      }
    })

    .state('sgcontenteditorPreviewPage', {
      parent: 'sgcontenteditorEditPage',
      url: '/preview',
      params: {
        pageInfo: null
      },
      template: require('./sgcontenteditorPage/viewPage.html'),
      controller: 'sgcontenteditorViewPageCtrl',
      controllerAs: 'vm',
      resolve: {
        page: function(sgcontenteditorService, $stateParams) {
          var page = sgcontenteditorService.getPageAdmin($stateParams.page_id);
          return page;
        }
      }
    });
}
