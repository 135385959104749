'use strict';

export default function ($animate) {
  'ngInject';

  return {
    controller: function ($scope) {
      'ngInject';
      $scope.SelectElement = function SelectElement() {
        $scope.$emit("ElementSelected", $scope.element, $scope.parentElement);

        let grouptab, tabIndex;
        switch ($scope.element.type) {
          case "tab":
            grouptab = $scope.$parent.$parent.element;
            tabIndex = grouptab.children.indexOf($scope.element);
            $scope.$emit('GroupedTabs.Selected.Node.Emit', grouptab, tabIndex, 0);
            break;
          case "subtab":
            grouptab = $scope.$parent.$parent.$parent.$parent.$parent.$parent.element;
            let tab = $scope.$parent.$parent.element;
            tabIndex = grouptab.children.indexOf(tab);
            let subtabIndex = tab.children.indexOf($scope.element);
            $scope.$emit('GroupedTabs.Selected.Node.Emit', grouptab, tabIndex, subtabIndex);
            break;
          default:
            $scope.$emit('GroupedTabs.ChildElementSelected');
            break;
        }

      };

      $scope.$on('Element.Selected', function (event, element) {
        if (element == $scope.element) {
          $scope.SelectElement();
        }
      });

      if ($scope.element.type == 'tab') {
        $scope.$on('GroupedTabs.Selected.ElementTab', function (event, grouptab, tabIndex) {
          var thisGrouptab = $scope.$parent.$parent.$parent.$parent.element;
          var thisTab = $scope.element;
          var thisTabIndex = thisGrouptab.children.indexOf(thisTab);
          if (grouptab == thisGrouptab && tabIndex == thisTabIndex) {
            $scope.$emit("Element.Selected.Emit", $scope.element);
          }
        });
      }

      if ($scope.element.type == 'subtab') {
        $scope.iconClass = '';
        $scope.$on('GroupedTabs.Selected.Element', function (event, grouptab, tabIndex, subtabIndex) {
          var thisGrouptab = $scope.$parent.$parent.$parent.$parent.$parent.$parent.element;
          var thisTab = $scope.$parent.$parent.element;
          var thisTabIndex = thisGrouptab.children.indexOf(thisTab);
          var thisSubtabIndex = thisTab.children.indexOf($scope.element);
          if (grouptab == thisGrouptab && tabIndex == thisTabIndex && subtabIndex == thisSubtabIndex) {
            $scope.$emit("Element.Selected.Emit", $scope.element);
          }
        });

        //When a child node is selected, send event to change the display to match
        $scope.$on('GroupedTabs.ChildElementSelected', function (event) {
          event.stopPropagation();
          var grouptab = $scope.$parent.$parent.$parent.$parent.$parent.$parent.element;
          var tab = $scope.$parent.$parent.element;
          var tabIndex = grouptab.children.indexOf(tab);
          var subtabIndex = tab.children.indexOf($scope.element);
          $scope.$emit('GroupedTabs.Selected.Node.Emit', grouptab, tabIndex, subtabIndex);
        });
      }

      //This will allow the tree to be expanded per subtab, hides elements under other subtabs
      //to clean up the tree
      $scope.Expand = true;
      if($scope.element.type == 'subtab' || $scope.element.type == 'section' || $scope.element.type == 'columns') {
        $scope.$watch('selectedElement', function() {
          $scope.Expand = $scope.element == $scope.selectedElement || CheckDescendentsForSelect($scope.element.children);
        });
      }

      //Cycle through all descendents and see if any are the currently selected element
      function CheckDescendentsForSelect(children) {
        if (!children) {
          return false;
        }
        for(let i = 0; i < children.length; i++) {
          if(children[i] == $scope.selectedElement) {
            return true;
          }
          if(CheckDescendentsForSelect(children[i].children)) {
            return true;
          }
        }
        return false;
      }
    },
    link: function link($scope, element) {
      $animate.enabled(false, element);
    },
    template: require('./treeviewNode.html'),
    scope: {
      element: '=',
      selectedElement: '=',
      parentElement: '='
    }
  };
}
