'use strict';

export default function () {
  return {
    controller: function ($scope) {
      'ngInject';
    },
    template: require('./column.html')
  };
};
