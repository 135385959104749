'use strict';

export default function () {
  return {
    controller: function ($scope, $sce) {
      'ngInject';
      $scope.edit = false;

      $scope.to_trusted = function (html_code) {
        return $sce.trustAsHtml(html_code);
      };
    },
    template: require('./html.html')
  };
};
