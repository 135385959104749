'use strict';

import _ from 'lodash';

export default function () {
  return {
    controller: function ($scope, $timeout, $state, helperService) {
      'ngInject';
      $scope.selectedTabIndex = 0;
      $scope.selectedSubtabIndex = 0;
      if (!$scope.educator && $scope.element.children.length > 0) {
        let firstTab = $scope.element.children[0];
        for (let i = 0; i < firstTab.children.length; i++) {
          if (!firstTab.children[i].data.teacheronly) {
            $scope.selectedSubtabIndex = i;
            break;
          }
        }
      }
      $scope.hideDropdown = false;

      //Check state and set tabs accordingly
      if ($state.params["tab" + ($scope.tabdepth + 1)] !== null) {
        _setTabs($state.params);
      }

      function _setTabs(params) {
        _.forEach($scope.element.children, function (tab, tabkey) {
          let tabname = helperService.cleanString(tab.data.name);
          let tabparam = params["tab" + ($scope.tabdepth + 1)];
          if (tabname === tabparam) {
            $scope.selectedTabIndex = tabkey;
            _.forEach(tab.children, function (subtab, subtabkey) {
              let subname = _getSubTabClean(subtab);
              let subparam = params["tab" + ($scope.tabdepth + 2)];
              if (subname === subparam && ($scope.educator || !subtab.data.teacheronly)) {
                $scope.selectedSubtabIndex = subtabkey;
              }
            });
          }
        });
      }

      function _getSubTabClean(subtab) {
        let name = subtab.data.teacheronly ? 't-' : 's-';
        name += subtab.data.name;
        return helperService.cleanString(name);
      }

      $scope.$on('GroupedTabs.LocationChanged', function (event, params) {
        _setTabs(params);
      });

      $scope.$on('GroupedTabs.GetLocation', function (event, params) {
        var tab = $scope.element.children[$scope.selectedTabIndex];
        var subtab = tab.children[$scope.selectedSubtabIndex];

        params['tab' + ($scope.tabdepth + 1)] = helperService.cleanString(tab.data.name);
        params['tab' + ($scope.tabdepth + 2)] = _getSubTabClean(subtab);
      });

      $scope.SelectSubtab = function SelectTab(tabIndex, subtabIndex) {
        $scope.selectedTabIndex = tabIndex;
        $scope.selectedSubtabIndex = subtabIndex;
        $scope.hideDropdown = true;
        $timeout(function UnhideDropDown() {
          $scope.hideDropdown = false;
        }, 10);

        $scope.$emit('GroupedTabs.Selected.Element.Emit', $scope.element, tabIndex, subtabIndex);
        SetLocation();
      };

      $scope.Selecttab = function SelectTab(tabIndex) {
        $scope.selectedTabIndex = tabIndex;
        $scope.selectedSubtabIndex = 0;
        $scope.hideDropdown = true;
        $timeout(function UnhideDropDown() {
          $scope.hideDropdown = false;
        }, 10);

        $scope.$emit('GroupedTabs.Selected.ElementTab.Emit', $scope.element, tabIndex);
        SetLocation();
      };

      $scope.$on('GroupedTabs.Selected.Node', function GroupTabsSelected(event, element, tabIndex, subtabIndex) {
        if (element == $scope.element) {
          $scope.selectedTabIndex = tabIndex;
          $scope.selectedSubtabIndex = subtabIndex;
          SetLocation();
        }
      });

      //Set the location without reloading useful for bookmarking, but doesn't work with history yet
      function SetLocation() {
        var params = {};
        params.page_id = $state.params.page_id;
        for (var i = 1; i < $scope.tabdepth + 1; i++) {
          params['tab' + i] = $state.params['tab' + i];
        }

        var tab = $scope.element.children[$scope.selectedTabIndex];
        var subtab = tab.children[$scope.selectedSubtabIndex];

        params['tab' + ($scope.tabdepth + 1)] = helperService.cleanString(tab.data.name);
        params['tab' + ($scope.tabdepth + 2)] = _getSubTabClean(subtab);

        $state.go($state.current.name, params, { notify: false });
      }
    },
    template: require('./groupedtabs.html')
  };
};
