'use strict';

export default function ($uibModal) {
  'ngInject';
  return {
    controller: function ($scope) {
      'ngInject';
      $scope.AddElement = function AddElement() {
        var modalInstance = $uibModal.open({
          template: require('../popups/elementList.html'),
          controller: 'sgcontenteditorEditorElementListCtrl',
          controllerAs: '$ctrl',
          windowClass: 'sgcontenteditor-center-modal'
        });

        modalInstance.result.then(function(element) {
          if(!$scope.children) {
            $scope.children = [];
          }
          $scope.children.push(element);
        });
      };
      $scope.MoveElement = function MoveElement(currentIndex, change) {
        var element = $scope.children.splice(currentIndex, 1)[0];
        $scope.children.splice(currentIndex + change, 0, element);
      };
    },
    template: require('./childElements.html'),
    scope: {
      children: "=",
      title: "@",
      addText: "@"
    }
  };
};
