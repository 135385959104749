'use strict';

export default function($compile, sgcontenteditorService) {
  'ngInject';

  return {
    scope: {
      element: '=',
      editor: '=',
      tabdepth: '=',
      pageid: '=',
      properties: '@',
      educator: '='
    },
    link: function link($scope, element) {
      if(!$scope.element.element_id) {
        $scope.element.element_id = sgcontenteditorService.generateUUID();
      }

      $scope.elementSelect = function($event) {
        $event.stopPropagation();
        $scope.$emit('Element.Selected.Emit', $scope.element);
      };

      $scope.$watch('element', function() {
        var isproperties = $scope.properties !== undefined && $scope.properties;
        var elementPrefix = isproperties ? 'sgcontenteditor-elementprop-' : 'sgcontenteditor-element-';
        var elementName = elementPrefix + $scope.element.type.toLowerCase();
        var attrs = ' in-view="isVisible = isVisible || $inview" tabdepth="tabdepth" pageid="pageid" element="element" editor="editor" educator="educator" style="outline: none !important;"';
        //attrs += ` id="${$scope.element.element_id}"`;
        if($scope.element.type != 'groupedtabs') {
          attrs += ' ng-click="elementSelect($event)"';
        }
        var html = '<' + elementName + attrs + '></' + elementName + '>';

        //if($scope.element.data.cssitems && $scope.element.data.cssitems.length) {
        //  html += "<style>";
        //  _.each($scope.element.data.cssitems, function(item) {
        //    html += `#Element-${$scope.element.element_id} ${item.class} { ${item.css} }`;
        //  });
        //  html += "</style>";
        //  html += "<div>";
        //  _.each($scope.element.data.cssitems, function(item) {
        //    html += `#Element-${$scope.element.element_id} ${item.class} { ${item.css} }`;
        //  });
        //  html += "</div>";
        //}

        element.html(html);
        //element.attr("id", 'Element-' + $scope.element.element_id);
        $compile(element.contents())($scope);
      });
    }
  };
};
