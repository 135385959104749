'use strict';

import _ from 'lodash';

export default function () {
  return {
    controller: function($scope, $http, $cookies) {
      'ngInject';

      $scope.menu = $cookies.get('savedMenu');

      $http.get('/api/pages/getmenu').then(_successCallback, _errorCallback);

      function _successCallback(response) {
        $scope.menu = response.data;
        $cookies.put('savedMenu', $scope.menu, { path: "/"});
      }

      function _errorCallback(response) {
        console.log("Error Getting Menu", response);
      }
    },
    template: require('./dhheader.html')
  };
};
