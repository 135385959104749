'use strict';

import _ from 'lodash';
//import angular from 'angular';

// eslint-disable-next-line no-unused-vars
export default function ($scope, sgcontenteditorService, page, $state, helperService) {
  'ngInject';
  var self = this;

  if (page.status === 404 && $state.params.pageInfo) {
    $state.go('sgcontenteditorListPages', { noAccess: true, page: $state.params.pageInfo });
  }

  self.page = page;

  let cleanName = helperService.cleanString(page.name);
  if (cleanName != $state.params.page_name) {
    $state.go($state.current.name, { page_name: cleanName }, { notify: false });
  }


  //Get footer
  sgcontenteditorService.getPage(10).then(function Success(footerPage) {
    page.elements.push(footerPage.elements);
  }, function Fail() {
    console.warn("Failed to get footer");
  });
};
