'use strict';

import _ from 'lodash';

export default function () {
  return {
    controller: function ($scope) {
      'ngInject';
    },
    template: require('./section.html')
  };
};
