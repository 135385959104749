'use strict';

import _ from 'lodash';

export default function sgcontenteditorListPagesCtrl($scope, sgcontenteditorService, $state, $uibModal) {
  'ngInject';
  var self = this;

  self.pages = [];
  self.totalCount = 0;
  self.pageSize = 20;
  self.page = 1;
  self.sort = '';

  self.search = '';

  _init();

  
  var preventDouble = true;

  $scope.$watch('[vm.page, vm.sort]', _getPageList);
  $scope.$watch('vm.search', function(oldvalue) {
    if(preventDouble) {
      preventDouble = false;
      return;
    }

    //Reset to page 1 on search so that we get the total count and don't get stuck on a page that doesn't exist
    self.page = 1;
    _getPageList();
  });

  function _init() {
    if ($state.params.error) {
      self.dialog = $uibModal.open({
        template: '<div class="modal-header"><h3>Error</h3></div><div class="modal-body">' + $state.params.error + '</div>'
      });
    }
  }

  self.Join = function(list) {
    let filteredList = _.filter(list, function(o) {
      return o !== null && o !== '';
    });
    return filteredList.join(', ');
  };

  function _getPageList() {
    let limit = self.pageSize;
    let skip = self.pageSize * (self.page - 1);
    sgcontenteditorService.listPages(limit, skip, self.search, self.sort).then(function (result) {
      if (!result.errMsg) {
        self.pages = result.pages;
          if (result.totalCount) {
          self.totalCount = result.totalCount;
        }
      }
      else {
        console.error(result.status + " " + result.errMsg);
      }
    });
  }
};
