'use strict';

//import angular from 'angular';

export default function ($uibModal) {
  'ngInject';
  return {
    controller: function ($scope, $rootScope, sgcontenteditorService) {
      'ngInject';
      $scope.progress = [];

      $scope.uploadFile = function (files) {
        let page_id = sgcontenteditorService.getPageIdFromScope($scope);
        console.log($scope);
        if (!page_id) {
          $uibModal.open({ template: '<div class="modal-header"><h3>New Page Not Saved</h3></div><div class="modal-body">Please save before uploading images.</div>'});
          return;
        }
        if (angular.isDefined($scope.filenameCallback)) {
          $scope.filenameCallback({ files: files });
        }
        else {
          if ($scope.progress.length === 0) {
            $scope.progress.push({ value: 'Uploading...' });
          }


          for(let i = $scope.progress.length - 1; i >= 0; i--) {
            if($scope.progress[i].value == 'Uploading...') {
              $scope.progress.splice(i, 1);
            }
          }
          for(let i = 0; i < files.length; i++) {
            _upload(files[i], page_id);
          }
        }
      };

      $scope.multiple = angular.isDefined($scope.multiple) ? $scope.multiple : false;

      $scope.id = sgcontenteditorService.generateUUID();

      function _upload(file, page_id) {
        var fileProgress = { value: '0%', filename: file.name };
        $scope.progress.push(fileProgress);
        sgcontenteditorService.uploadFile(file, "page" + page_id + "files", function(value) {
          var percent = Math.round(value * 100);
          if (percent === 100) {
            fileProgress.value = 'Finalizing. Please wait ...';
          }
          else {
            fileProgress.value = percent + '%';
          }
        }).then(function (url) {
          if (angular.isArray($scope.uploadTo)) {
            $scope.uploadTo.push({
              url: url,
              filename: file.name,
              created: new Date()
            });
          }
          else {
            $scope.uploadTo = url;
          }
          $scope.progress = $scope.progress.filter(function (item) {
            return item != fileProgress;
          });
        }, function (error) {
          fileProgress.value = error;
          console.error(error);
        });
      }
    },
    template: require('./fileUpload.html'),
    scope: {
      uploadTo: "=?",
      filenameCallback: "&?",
      multiple: "=?"
    }
  };
};
