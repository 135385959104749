'use strict';

/*eslint no-undef:0*/

export default function ($uibModal, $rootScope, sgcontenteditorService) {
  'ngInject';


  var ckEditorConfigured = false;

  function _link(scope, element, attr, ngModel) {
    if (!element[0].id) {
      element[0].id = sgcontenteditorService.generateUUID();
    }

    ngModel.$formatters.push(function (value) {
      element.html(value);
    });

    //Manually add ckeditor script reference
    if (!document.getElementById("ckeditorscript")) {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = 'https://cdn.ckeditor.com/4.6.2/full-all/ckeditor.js';
      s.id = 'ckeditorscript';
      document.head.appendChild(s);
    }

    var editorOptions = {
      toolbar: [
        ['Format', 'Styles'],
        ['Bold', 'Italic'],
        ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
        ['BidiLtr', 'BidiRtl'],
        ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote'],
        ['Table', 'HorizontalRule', 'SpecialChar'],
        ['Link', 'Unlink', 'Image'], ['Sourcedialog']
      ],
      filebrowserBrowseUrl: '#',
      filebrowserImageBrowseUrl: '#',
      stylesSet: 'my_styles',
      baseFloatZIndex: 10000,
      extraPlugins: 'sourcedialog',
      removePlugins: 'sourcearea',
      allowedContent: true

    };

    var interval = null;
    var isSetup = false;
    interval = setInterval(function () {
      //Wait for CKEDitor to be loaded and $modelValue to be loaded
      if (window.CKEDITOR && window.CKEDITOR.stylesSet && ngModel.$modelValue !== null && ngModel.$modelValue !== undefined) {
        if (!isSetup) {
          isSetup = true;
          _setupFileDialog();

          clearInterval(interval);
          element.html(ngModel.$modelValue);

          var instance = CKEDITOR.instances[element.attr('id')];
          if (instance) {
            CKEDITOR.remove(instance);
          }
          element.attr("contenteditable", true);
          var editorInstance = CKEDITOR.inline(element.attr('id'), editorOptions);

            console.log(element, editorInstance);

          // update ngModel on change
          editorInstance.on('change', function () {
            ngModel.$setViewValue(this.getData());
          });

          scope.$on('$destroy', function () {
            var focusManager = new CKEDITOR.focusManager(editorInstance);
            focusManager.blur(true);
            editorInstance.destroy();
          });
        }
      }
    }, 10);
  }

  function _setupFileDialog() {
    if (!ckEditorConfigured) {
      ckEditorConfigured = true;
      CKEDITOR.disableAutoInline = true;
      CKEDITOR.stylesSet.add('my_styles', [
        { name: '1/3 Image', element: 'img', styles: { width: '31%', padding: "0 2% 2% 0" } },
        { name: '1/2 Image', element: 'img', styles: { width: '48%', padding: "0 2% 2% 0" } },
        { name: 'Clear Float', element: 'h2', styles: { clear: "both" } },
        { name: 'Clear Float', element: 'h3', styles: { clear: "both" } },
        { name: 'Clear Float', element: 'p', styles: { clear: "both" } },
        { name: 'Green Buton', element: 'a', attributes: { class: 'GreenButton' } },
        { name: 'Purple Button', element: 'a', attributes: { class: 'PurpleButton' } },
        { name: 'Contact Purple Button', element: 'a', attributes: { class: 'ContactButton' } },
        { name: 'LightPurple Button', element: 'a', attributes: { class: 'LightPurpleButton' } },
        { name: 'GreenText', element: 'span', attributes: { class: 'GreenText' } },
        { name: 'PurpleText', element: 'span', attributes: { class: 'PurpleText' } },
        { name: 'LightPurpleText', element: 'span', attributes: { class: 'LightPurpleText' } },
        { name: 'Thin Text', element: 'span', attributes: { class: 'ThinText' } },
      ]);

      CKEDITOR.on('dialogDefinition', function (event) {
        var dialogName = event.data.name,
          dialogDefinition = event.data.definition,
          infoTab,
          browse;

        // Check if the definition is from the dialog window you are interested in (the "Image" dialog window).
        if (dialogName == 'image' || dialogName == 'link') {
          dialogDefinition.minWidth = 900;
          // Get a reference to the "Image Info" tab.
          infoTab = dialogDefinition.getContents('info');

          // Get the "Browse" button
          browse = infoTab.get('browse');

          // Override the "onClick" function
          browse.onClick = function () {
            var self = this;
            var dialog = self.getDialog();
            var editor = dialog.getParentEditor();
            var callBack = editor._.filebrowserFn;
            // var win;

            // This may or may not be needed.  Got it from ckeditor docs.
            editor._.filebrowserSe = self;

            if (dialogName == 'image') {
              _showImageSelectorDialog(callBack, 'images');
            }
            else {
              _showImageSelectorDialog(callBack, 'files');
            }
          };
        }
      });
    }
  }

  function _showImageSelectorDialog(callback, type) {
    $rootScope.$emit('ShowFileBrowser', type, callback);
  }

  return {
    require: 'ngModel',
    link: _link
  };
};
