'use strict';

export default function () {
  'ngInject';
  return {
    template: require('./dhheader.html'),
    scope: {
      pageid: "=",
      element: "="
    }
  };
};
