'use strict';

export default function() {
  return {
    controller: function($scope) {
      'ngInject';

      $scope.AddCol = function AddCol() {
        $scope.element.children.push({
          type: 'column',
          children: []
        });
      };
      $scope.MoveElement = function MoveElement(index, change) {
        var tab = $scope.element.children.splice(index, 1)[0];
        $scope.element.children.splice(index + change, 0, tab);
      };
    },
    template: require('./columns.html'),
    scope: {
      pageid: "=",
      element: "="
    }
  };
};
