'use strict';

import _ from 'lodash';
//import angular from 'angular';

export default function () {
  return {
    controller: function($scope, $rootScope, sgcontenteditorService, $uibModal, $state) { //, consts
      'ngInject';

      //if (!_.isArray($scope.vm.page.tags)) {
      //  $scope.vm.page.tags = [];
      //}
      //$scope.tagKeys = [consts.KEY_CODE.ENTER, consts.KEY_CODE.SPACE, consts.KEY_CODE.COMMA, consts.KEY_CODE.SEMICOLON, consts.KEY_CODE.TAB];

      $scope.pageImages = function () {
        $rootScope.$emit('ShowFileBrowser', 'images');
      };

      $scope.pageFiles = function () {
        $rootScope.$emit('ShowFileBrowser', 'files');
      };

      //$scope.possibletags = {};
      //sgcontenteditorService.getTags().then(function callbackSuccess(tags) {
      //  $scope.possibletags = tags;
      //}, function callbackFail() { });

      $scope.importsgcontenteditor = function () {
        let modalInstance = $uibModal.open({
          template: '<div class="modal-body">Paste JSON<textarea style="width: 100%; height: 200px;" ng-model="json"></textarea><br /><button class="btn btn-primary" ng-click="$close(json)">Import</button></div>',
          size: 'lg'
        });

        modalInstance.result.then(function (json) {
          $scope.vm.page = angular.fromJson(json);
          $scope.$emit("ElementSelected", $scope.vm.page, null);
          CheckForsgcontenteditorFiles();
        });
      };


      $scope.sgcontenteditorfiles = 0;
      function CheckForsgcontenteditorFiles() {
        let json = angular.toJson($scope.vm.page);
        let re = /https:\/\/sgcontenteditorfiles[^\\"]+/g;
        let files = json.match(re);
        if (files && files.length > 0) {
          files = _.uniq(files);
          $scope.sgcontenteditorfiles = files.length;
          $scope.HassgcontenteditorFile = true;
        }
        else {
          $scope.HassgcontenteditorFile = false;
        }
      }

      CheckForsgcontenteditorFiles();

      $scope.files = [];
      $scope.math = Math;
      let pageJson = '';

      $scope.$watch(function () { return $scope.vm.page.pastVersions; }, function () {
        $scope.isPublished = false;
        _.each($scope.vm.page.pastVersions, function (pastVersion) {
          if (pastVersion.published) {
            $scope.isPublished = true;
          }
        });
      });

      $scope.unpublish = function () {
        sgcontenteditorService.confirm('Unpublish Page', 'Are you sure you want to unpublish this page?').then(function () {
          sgcontenteditorService.unpublish($scope.vm.page.page_id)
            .then(function (result) {
              if (!result.errMsg) {
                $state.reload();
              }
              else {
                self.dialog = $uibModal.open({
                  template: '<div class="modal-header"><h3>Error Unpublishing</h3></div><div class="modal-body">' + result.errMsg + '</div>'
                });
              }
            });
        });
      };

      $scope.delete = function (showError) {
        let error = showError ? '<br /><br />The name you typed does not match.' : '';
        sgcontenteditorService.prompt('Delete Page', 'To confirm you want to delete this page please type in the title of the page.' + error).then(function (name) {
          if (name === $scope.vm.page.title) {
            sgcontenteditorService.delete($scope.vm.page.page_id)
              .then(function (result) {
                if (!result.errMsg) {
                  $state.transitionTo('sgcontenteditorAdmin');
                }
                else {
                  self.dialog = $uibModal.open({
                    template: '<div class="modal-header"><h3>Error Unpublishing</h3></div><div class="modal-body">' + result.errMsg + '</div>'
                  });
                }
              });
          }
          else {
            $scope.delete(true);
          }
        });
      };
    },
    template: require('./pageProperties.html')
  };
}
