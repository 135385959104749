'use strict';

import _ from 'lodash';
//import angular from 'angular';

export default function ($window) {
  'ngInject';

  return {
    scope: {
      value: '=',
      minValue: '=',
      maxValue: '=',
      reverse: '='
    },
    link: function link($scope, element) {
      var startX = null;
      var startValue = null;

      angular.element($window).on('mousemove', _mouseMove).on('mouseup', _mouseUp);
      element.on('mousedown', _mouseDown);

      function _mouseDown(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        if (startX === null) {
          startX = evt.pageX;
          startValue = $scope.value;
        }
      }

      function _mouseUp(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        startX = null;
      }

      function _mouseMove(evt) {
        if (startX !== null) {
          evt.preventDefault();
          evt.stopPropagation();
          var dX = evt.pageX - startX;
          if ($scope.reverse) {
            dX = 0 - dX;
          }

          $scope.$apply(function () {
            $scope.value = startValue + dX;
            if (_.isNumber($scope.minValue) && $scope.value < $scope.minValue) {
              $scope.value = $scope.minValue;
            }
            if (_.isNumber($scope.maxValue) && $scope.value < $scope.maxValue) {
              $scope.value = $scope.maxValue;
            }
          });
        }
      }
    }
  };
};
