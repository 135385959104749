'use strict';

export default function () {
  return {
    controller: function ($scope) {
      'ngInject';
      $scope.AddTab = function AddTab() {
        $scope.element.children.push({
          type: 'tab',
          children: [],
          data: {
            name: '',
            description: ''
          }
        });
      };
      $scope.MoveElement = function MoveElement(index, change) {
        var tab = $scope.element.children.splice(index, 1)[0];
        $scope.element.children.splice(index + change, 0, tab);
      };
    },
    template: require('./groupedtabs.html')
  };
};
