'use strict';

//import angular from 'angular';

export default function ($uibModalInstance, sgcontenteditorService) {
  'ngInject';
  var self = this;

  self.elementlist = [
    {
      name: 'Columns',
      description: 'Full Width Columns',
      elementDefaults: {
        type: 'columns',
        children: [
          { type: 'column', children: [] }
        ],
        data: {
          contain: false
        }
      }
    },
    {
      name: 'Section',
      description: 'Full Width Section',
      elementDefaults: {
        type: 'section',
        data: {
          contain: true
        }
      }
    },
    {
      name: 'HTML Text',
      description: 'Standard HTML Text that can be formatted and include images',
      elementDefaults: { type: 'html', data: { text: 'New HTML Element' } }
    },
    {
      name: 'Grouped Tabs (Unpopulated)',
      description: 'sgcontenteditor Tabs with Sub Groups',
      elementDefaults: {
        type: 'groupedtabs',
        children: [
          {
            type: 'tab',
            data: { name: 'Tab 1', description: 'Description 1' },
            children: [{ type: 'subtab', data: { name: 'Sub Tab 1-1', teacheronly: false }, children: [] }]
          },
          {
            type: 'tab',
            data: { name: 'Tab 2', description: 'Description 2' },
            children: [{ type: 'subtab', data: { name: 'Sub Tab 2-1', teacheronly: false }, children: [] }]
          },
          {
            type: 'tab',
            data: { name: 'Tab 3', description: 'Description 3' },
            children: [{ type: 'subtab', data: { name: 'Sub Tab 3-1', teacheronly: false }, children: [] }]
          },
          {
            type: 'tab',
            data: { name: 'Tab 4', description: 'Description 4' },
            children: [{ type: 'subtab', data: { name: 'Sub Tab 4-1', teacheronly: false }, children: [] }]
          }
        ]
      }
    },
    {
      name: 'Video',
      description: '',
      elementDefaults: { type: 'video', data: { aspectratio: '16by9' } }
    },
    {
      name: 'Popup',
      description: 'Popup with Elements',
      elementDefaults: {
        type: 'popuphtml',
        children: [
          { type: 'html', data: { text: "New HTML Element" } }
        ]
      }
    },
    {
      name: 'iFrame',
      description: '',
      elementDefaults: {
        type: 'iframe',
        data: {
          url: ''
        }
      }
    },
  ];

  self.ElementSelected = function ElementSelected(elementitem) {
    var element = angular.copy(elementitem.elementDefaults);
    element.element_id = sgcontenteditorService.generateUUID();
    $uibModalInstance.close(element);
  };
};
