'use strict';

import _ from 'lodash';
//import angular from 'angular';

export default function ($scope, $rootScope, $uibModalInstance, sgcontenteditorService, currentFiles, $state, page_id) {
  'ngInject';
  var self = this;

  self.files = currentFiles;
  self.tempfiles = [];
  self.shownfiles = currentFiles;

  $scope.$watchCollection(() => self.files, _updateShowFiles);
  $scope.$watchCollection(() => self.tempfiles, _updateShowFiles);

  function _updateShowFiles() {
    self.shownfiles = self.files.concat(self.tempfiles);
  }

  self.fileProgress = [];
  self.currentPage = 1;
  self.pageSize = 6;

  self.UrlSelected = function (url) {
    $uibModalInstance.close(url);
  };


  $scope.page_id = page_id;
  self.GetFileNames = function (files) {
    self.currentPage = 1;

    angular.forEach(files, function (value) {
      self.tempfiles.push({
        filename: value.name,
        url: 'temp',
        value: 'Uploading...'
      });
    });

    for(var i = 0; i < files.length; i++) {
      var file = files[i];

      _upload(file);
    }

  };

  self.pageRange = function () {
    var start = self.shownfiles.length - (self.currentPage - 1) * self.pageSize;
    var end = start - 6;

    var range = [];
    for (var i = start - 1; i >= end; i--) {
      range.push(i);
    }
    return range;
  };

  self.close = function () {
    $uibModalInstance.dismiss('cancel');
  };

  self.IsImage = function (file) {

    if (file.filename.toLowerCase().endsWith(".png")) {
      return true;
    }
    if (file.filename.toLowerCase().endsWith(".gif")) {
      return true;
    }
    if (file.filename.toLowerCase().endsWith(".jpg")) {
      return true;
    }
    if (file.filename.toLowerCase().endsWith(".jpeg")) {
      return true;
    }

    return false;
  };


  function _upload(file) {
    var fileProgress = null;
    //Remove temprary status
    for (var i = self.tempfiles.length - 1; i >= 0; i--) {
      if (self.tempfiles[i].filename === file.name && self.tempfiles[i].url === 'temp') {
        fileProgress = self.tempfiles[i];
      }
    }
    fileProgress.value = '0%';
    sgcontenteditorService.uploadFile(file, "page" + page_id + "files", function(value) {
      var percent = Math.round(value * 100);
      if (percent === 100) {
        fileProgress.value = 'Finalizing. Please wait ...';
      }
      else {
        fileProgress.value = percent + '%';
      }
    }).then(function (url) {
      self.tempfiles = _.without(self.tempfiles, fileProgress);

      self.files.push({
        url: url,
        filename: file.name,
        created: new Date()
      });
    }, function (error) {
      fileProgress.value = error;
      console.error(error);
    });
  }


};
