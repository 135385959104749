'use strict';

//import angular from 'angular';
import _ from 'lodash';

export default function($scope, $rootScope, sgcontenteditorService, page, $state, $cookies, $uibModal, $location, $window, $timeout, $compile) {
  'ngInject';
    var self = this;
    
    console.log($scope);

  //Setup Iframe for content
  //  $timeout(() => {
  //    $("iframe").contents().find('html').html(`
  //<html>
  //<head>
  //    <meta charset="utf-8" />
  //    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //    <link rel="icon" href="/favicon.png" sizes="16x16" type="image/png">

  //    <title>Inner</title>
  //    <link rel="stylesheet" href="https://ajax.googleapis.com/ajax/libs/angular_material/1.1.0/angular-material.min.css">
  //        <link href="/css/reset.css" rel="stylesheet" />
  //        <link rel="stylesheet" href="/lib/bootstrap/dist/css/bootstrap.css" />
  //        <link rel="stylesheet" href="/css/site.css" />
  //    <link rel="stylesheet" href="https://ajax.googleapis.com/ajax/libs/angular_material/1.1.0/angular-material.min.css">
  //    <link href="/css/admin.css" rel="stylesheet" />
  //    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700" rel="stylesheet">
  //       <!-- <script src="/lib/jquery/dist/jquery.js"></script>



  //    <script src="https://cdnjs.cloudflare.com/ajax/libs/angular.js/1.6.5/angular.min.js"></script>

  //    <script src="https://cdnjs.cloudflare.com/ajax/libs/angular.js/1.6.5/angular-animate.min.js"></script>
  //    <script src="https://cdnjs.cloudflare.com/ajax/libs/angular.js/1.6.5/angular-aria.min.js"></script>
  //    <script src="https://cdnjs.cloudflare.com/ajax/libs/angular.js/1.6.5/angular-messages.min.js"></script>
  //    <script src="https://cdnjs.cloudflare.com/ajax/libs/angular.js/1.6.5/angular-sanitize.min.js"></script>

  //    <script src="https://ajax.googleapis.com/ajax/libs/angular_material/1.1.0/angular-material.min.js"></script>

  //    <script src="//unpkg.com/@uirouter/angularjs/release/angular-ui-router.min.js"></script>
  //    <script src="https://cdnjs.cloudflare.com/ajax/libs/angular-ui-bootstrap/2.5.0/ui-bootstrap.min.js"></script>
  //    <script src="https://cdnjs.cloudflare.com/ajax/libs/angular-ui-bootstrap/2.5.0/ui-bootstrap-tpls.min.js"></script>
  //    <script src="//ajax.googleapis.com/ajax/libs/angularjs/1.6.5/angular-cookies.js"></script>
  //    <script src="/js/main.build.js"></script>


  //    <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.20.1/moment.min.js"></script>
  //    <script src="https://cdnjs.cloudflare.com/ajax/libs/angular-moment/1.2.0/angular-moment.min.js"></script>

  //    -->
  //</head>
  //<body ng-app="dhApp2">
  //    <div ng-controller="page2">
  //        <sgcontenteditor-editor-elements-display editor="true" elements="vm.page.elements" tabdepth="0" pageid="vm.page.page_id"></sgcontenteditor-editor-elements-display>
  //    </div>
  //</body>
  //</html>
  //`);
  //    $compile($("iframe").contents().find('html').contents())($scope);
  //  }, 1);

  if(page.status) {
    $state.go('sgcontenteditorAdmin', { error: 'Error loading page with page_id (' + $state.params.page_id + ')<br />' + page.status + ': ' + page.errMsg });
  }

  $scope.window = window;
  $scope.Math = Math;
  $scope.page_id = page.page_id;
  self.page = page;
  window.page = self.page;
  self.selectedElement = self.page;
  self.selectedElementParent = null;
  self.pageChanged = false;
  self.leftPaneWidth = $cookies.get('sgcontenteditorLeftPaneWidth') || 250;
    self.rightPaneWidth = $cookies.get('sgcontenteditorRightPaneWidth') || 425;

  sgcontenteditorService.getCurrentUser()
    .then(function(user) {
      self.user = user;
    });

  $scope.$watch('vm.leftPaneWidth', function(newValue) {
      $cookies.put('sgcontenteditorLeftPaneWidth', newValue);
  });

  $scope.$watch('vm.rightPaneWidth', function(newValue) {
      $cookies.put('sgcontenteditorRightPaneWidth', newValue);
  });

  // Handle the user trying to navigate away with unsaved changes
  $scope.$on('$locationChangeStart', function(event) {
    if(self.pageChanged) {
      var answer = $window.confirm("There are unsaved changes, are you sure you want to leave this page?");
      if(!answer) {
        event.preventDefault();
      }
    }
  });
  $window.onbeforeunload = function(e) {
    if(self.pageChanged) {
      var dialogText = 'There are unsaved changes, are you sure you want to leave this page?';
      e.returnValue = dialogText;
      return dialogText;
    }
    else {
      return null;
    }
  };
  $scope.$on('$destroy', function() {
    $window.onbeforeunload = undefined;
  });

  _init();

  //self.feature = function () {
  //  if (self.pageChanged) {
  //    sgcontenteditorService.alert('Unsaved Changes', 'Please save page before featuring the page');
  //  }
  //  else {
  //    sgcontenteditorService.confirm('Feature', 'Are you sure you want to feature this page? (The featured page will not display until a new version is published)').then(function () {
  //      sgcontenteditorService.feature(self.page.page_id).then(function () {
  //        sgcontenteditorService.getPageAdmin(self.page.page_id).then(function(a) {
  //          console.log('Page Reloaded');
  //          self.page = a;
  //          _startWatchingPage();
  //        });
  //      });
  //    });
  //  }
  //};

  //self.makePublic = function () {
  //  sgcontenteditorService.confirm('Make Public', 'Are you sure you want to make this page public?').then(function () {
  //    self.savePage("PublicAndClose");
  //  });
  //};

  $scope.LastAction = 'Save';
  var confirmSave = false;


  //Handle Ctrl-S
  $(window).bind('keydown', function(event) {
    if(event.ctrlKey || event.metaKey) {
      switch(String.fromCharCode(event.which).toLowerCase()) {
        case 's':
          event.preventDefault();
          self.savePage('Save');
          break;
      }
    }
  });

  self.savePage = function savePage(action) {
    if(!confirmSave && action == 'Save') {
      sgcontenteditorService.confirm('Save', 'Would you like to publish also?', 'Save And Publish', 'Save Only').then(
        function SaveAndPublish() {
          confirmSave = true;
          self.savePage('SaveAndPublish');
        }, function SaveOnly() {
          confirmSave = true;
          self.savePage('Save');
        });
      return;
    }
    $scope.LastAction = action;

    _showDialog('Saving');
    if(!action) {
      action = "";
    }
    var pageCopy = angular.copy(self.page);
    pageCopy.published = action.includes('Publish') ? true : false;

    var querystring = '';
    if(action.includes('Public')) {
      querystring += '?makePublic=1';
    }
    sgcontenteditorService.savePage(pageCopy, querystring)
      .then(function(result) {
        _closeDialog();
        if(!result.errMsg) {
          //if (action.includes('Close')) {
          //  self.pageChanged = false;
          //  $state.transitionTo('sgcontenteditorAdmin');
          //}
          //          else {
          if(self.page.page_id === 0) {
            $state.go('sgcontenteditorEditPage', { page_id: result.page_id });
            return;
          }


          if(self.selectedElement == self.page) {
            self.page = result;
            self.selectedElement = self.page;
            console.log("Selected Page");
          } else {

            self.page = result;
            if(self.selectedElement.element_id) {
              _reselectElement(self.page.elements, self.page);
            }
          }

          _startWatchingPage();
        }
        else {
          self.dialog = $uibModal.open({
            template: '<div class="modal-header"><h3>Error Saving</h3></div><div class="modal-body">' + result.errMsg + '</div>'
          });
          console.error(result.status + " " + result.errMsg);
        }
      });
  };

  function _reselectElement(children, parent) {
    _.each(children, function(c) {
      if(self.selectedElement.element_id == c.element_id) {
        console.log("Selected Element");
        self.selectedElement = c;
        self.selectedElementParent = parent;
      } else if(c.children) {
        _reselectElement(c.children, c);
      }
    });
  };

  self.closePage = function closePage() {
    if(!self.pageChanged) {
      $state.transitionTo('sgcontenteditorAdmin');
      return;
    }

    sgcontenteditorService.confirm('Unsaved Changes', 'Are you sure you want to discard changes?').then(function() {
      self.pageChanged = false;
      $state.transitionTo('sgcontenteditorAdmin');
    });
  };


  self.savedParams;

  self.duplicate = function() {
    $state.transitionTo('sgcontenteditorNewPage', { copyPage: angular.copy(self.page) });
  };

  self.showVersioning = function() {
    if(self.pageChanged) {
      $uibModal.open({ template: '<div class="modal-header"><h3>Changes Not Saved</h3></div><div class="modal-body">Please save changes before switching versions.</div>' });
      return;
    }
    self.savedParams = {};
    $scope.$broadcast('GroupedTabs.GetLocation', self.savedParams);
    $uibModal.open({
      template: require('./popups/versioning.html'),
      controller: 'sgcontenteditorEditorVersioningCtrl',
      controllerAs: '$ctrl',
      animation: false,
      windowClass: 'sgcontenteditor-center-modal',
      resolve: {
        pastVersions: function() {
          return self.page.pastVersions;
        },
        currentVersionTimestamp: function() {
          return self.page.created;
        }
      }
    }).result.then(_loadVersion);
  };

  self.loadingVersion = false;

  function _loadVersion(version) {
    _showDialog('Loading Previous Version');
    self.loadingVersion = true;
    sgcontenteditorService.getPageAdmin(self.page.page_id, version.created).then(function(result) {
      self.page = result;
      self.selectedElement = self.page;
      self.selectedElementParent = null;

      setTimeout(function() {
        _closeDialog();
        $scope.$broadcast('GroupedTabs.LocationChanged', self.savedParams);
        self.loadingVersion = false;
      }, 1);
    });
  }

  $scope.$on('Element.Selected.Emit', function GroupTabsSelectedElement(event, element) {
    $scope.$broadcast('Element.Selected', element);
  });

  $scope.$on('GroupedTabs.Selected.Element.Emit', function GroupTabsSelectedElement(event, element, tabIndex, subtabIndex) {
    $scope.$broadcast('GroupedTabs.Selected.Element', element, tabIndex, subtabIndex);
  });

  $scope.$on('GroupedTabs.Selected.ElementTab.Emit', function GroupTabsSelectedElement(event, element, tabIndex) {
    $scope.$broadcast('GroupedTabs.Selected.ElementTab', element, tabIndex);
  });

  $scope.$on('GroupedTabs.Selected.Node.Emit', function GroupTabsSelectedNode(event, element, tabIndex, subtabIndex) {
    $scope.$broadcast('GroupedTabs.Selected.Node', element, tabIndex, subtabIndex);
  });

  $scope.$on('ElementSelected', function ElementSelected(event, element, elementParent) {
    self.selectedElement = element;
    self.selectedElementParent = elementParent;
    $scope.$broadcast('SelectTab', 'Properties');
  });


  var unregisterShowFileBrowser = $rootScope.$on('ShowFileBrowser', function(event, type, callback) {
    var modalInstance = $uibModal.open({
      template: require('./popups/fileBrowser.html'),
      size: 'lg',
      windowClass: 'sgcontenteditor-center-modal',
      controller: 'sgcontenteditorEditorFileBrowserCtrl',
      controllerAs: '$ctrl',
      resolve: {
        currentFiles: function() {
          return self.page[type];
        },
        page_id: function() {
          return self.page.page_id;
        }
      }
    });

    modalInstance.result.then(function(url) {
      window.CKEDITOR.tools.callFunction(callback, url);
    });
  });

  $scope.$on('$destroy', unregisterShowFileBrowser);

  function _init() {
    _startWatchingPage();
  }

  function _startWatchingPage() {
    console.log("Start Watching Page");
    if(_.isFunction(self.pageWatchUnregister)) {
      self.pageWatchUnregister();
    }

    $timeout(function() {
      self.pageChanged = false;
      self.pageWatchUnregister = $scope.$watch('vm.page', _watchPage, true);
    }, 100);
  }

  function _watchPage(newValue, oldValue) {
    console.log("Stop Watching Page");
    newValue = clone(newValue);
    oldValue = clone(oldValue);

    if(!self.loadingVersion && !angular.equals(oldValue, newValue)) {
      //deepDiffMapper.map(oldValue, newValue);
      self.pageChanged = true;
      self.pageWatchUnregister();
      self.pageWatchUnregister = null;
    }
  }

  self.dialog = null;
  function _showDialog(message) {
    self.dialog = $uibModal.open({
      template: '<div class="modal-header"><h3>' + message + '</h3></div><div class="modal-body">Please wait...</div>'
    });
  }

  function _closeDialog() {
    self.dialog.close();
  }

  function clone(obj) {
    if(obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj) {
      return obj;
    }

    let temp;
    if(obj instanceof Date) {
      temp = new obj.constructor(); //or new Date(obj);
    }
    else {
      temp = obj.constructor();
    }

    for(var key in obj) {
      // eslint-disable-next-line prefer-reflect
      if(key != '$$hashKey' && Object.prototype.hasOwnProperty.call(obj, key)) {
        obj.isActiveClone = null;
        temp[key] = clone(obj[key]);
        // eslint-disable-next-line prefer-reflect
        delete obj.isActiveClone;
      }
    }

    return temp;
  }

  //var deepDiffMapper = function () {
  //    return {
  //        VALUE_CREATED: 'created',
  //        VALUE_UPDATED: 'updated',
  //        VALUE_DELETED: 'deleted',
  //        VALUE_UNCHANGED: 'unchanged',
  //        map: function (obj1, obj2) {
  //            if (this.isFunction(obj1) || this.isFunction(obj2)) {
  //                throw 'Invalid argument. Function given, object expected.';
  //            }
  //            if (this.isValue(obj1) || this.isValue(obj2)) {
  //                return {
  //                    type: this.compareValues(obj1, obj2),
  //                    data: (obj1 === undefined) ? obj2 : obj1
  //                };
  //            }

  //            var diff = {};
  //            for (var key in obj1) {
  //                if (this.isFunction(obj1[key])) {
  //                    continue;
  //                }

  //                var value2 = undefined;
  //                if ('undefined' != typeof (obj2[key])) {
  //                    value2 = obj2[key];
  //                }

  //                diff[key] = this.map(obj1[key], value2);
  //                if (diff[key].type == this.VALUE_CREATED || diff[key].type == this.VALUE_UPDATED || diff[key].type == this.VALUE_DELETED) {
  //                    console.log(obj1, obj2);
  //                }
  //            }
  //            for (var key in obj2) {
  //                if (this.isFunction(obj2[key]) || ('undefined' != typeof (diff[key]))) {
  //                    continue;
  //                }

  //                diff[key] = this.map(undefined, obj2[key]);
  //                if (diff[key].type == this.VALUE_CREATED || diff[key].type == this.VALUE_UPDATED || diff[key].type == this.VALUE_DELETED) {
  //                    console.log(obj1, obj2);
  //                }
  //            }

  //            return diff;

  //        },
  //        compareValues: function (value1, value2) {
  //            if (value1 === value2) {
  //                return this.VALUE_UNCHANGED;
  //            }
  //            if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
  //                return this.VALUE_UNCHANGED;
  //            }
  //            if ('undefined' == typeof (value1)) {
  //                return this.VALUE_CREATED;
  //            }
  //            if ('undefined' == typeof (value2)) {
  //                return this.VALUE_DELETED;
  //            }

  //            return this.VALUE_UPDATED;
  //        },
  //        isFunction: function (obj) {
  //            return {}.toString.apply(obj) === '[object Function]';
  //        },
  //        isArray: function (obj) {
  //            return {}.toString.apply(obj) === '[object Array]';
  //        },
  //        isDate: function (obj) {
  //            return {}.toString.apply(obj) === '[object Date]';
  //        },
  //        isObject: function (obj) {
  //            return {}.toString.apply(obj) === '[object Object]';
  //        },
  //        isValue: function (obj) {
  //            return !this.isObject(obj) && !this.isArray(obj);
  //        }
  //    }
  //}();
};
