'use strict';

export default function ($uibModal) {
  'ngInject';
  return {
    controller: function ($scope) {
      'ngInject';
      $scope.AddElement = function AddElement() {
        var modalInstance = $uibModal.open({
          templateUrl: '/sgcontenteditor-elementprops/sgcontenteditor-editor-elements.html',
          controller: 'sgcontenteditorEditorElementlistController',
          controllerAs: '$ctrl',
          windowClass: 'sgcontenteditor-center-modal'
        });

        modalInstance.result.then(function (element) {
          $scope.element.children.push(element);
        });
      };
    },
    template: require('./subtab.html')
  };
};
