'use strict';

import _ from 'lodash';

export default function () {
  return {
    controller: function ($scope, sgcontenteditorService) {
      'ngInject';
      $scope.DeleteElement = function DeleteElement() {
        var hasChildren = $scope.element.children && $scope.element.children.length > 0;

        if (!hasChildren) {
          _deleteElement();
          return;
        }
        sgcontenteditorService.confirm('Element Has Children', 'Deleting this element will also delete all the children under this element, are you sure you want to delete it?', 'Yes', 'No')
        .then(_deleteElement);
      };

      let _deleteElement = function() {
        var elementContainer = $scope.parent.elements ? $scope.parent.elements : $scope.parent.children;
        var elementIndex = _.findIndex(elementContainer, $scope.element);
        if (elementIndex > -1) {
          elementContainer.splice(elementIndex, 1);
          if($scope.parent) {
            $scope.$emit('Element.Selected.Emit', $scope.parent)
          } else {
            $scope.$emit('ElementSelected', null, []);
          }
        }
        else {
          console.error("Can't find element to delete");
        }
      };

      $scope.showcss = false;

      $scope.DeleteItem = function DeleteItem(item) {
        if(confirm('Are you sure you want to delete css?')) {
          let itemIndex = _.findIndex($scope.element.data.cssitems, item);
          $scope.element.data.cssitems.splice(itemIndex, 1);
        }
      };

      $scope.AddItem = function AddItem() {
        if(!$scope.element.data) {
          $scope.element.data = {};
        }
        if(!$scope.element.data.cssitems) {
          $scope.element.data.cssitems = [];
        }
        $scope.element.data.cssitems.push({
          class: "",
          css: ""
        });
      }
    },
    scope: {
      element: "=",
      parent: "=",
      page: "="
    },
    template: require('./elementProperties.html')
  };
};
