'use strict';

export default function () {
  'ngInject';
  return {
    controller: function ($scope, $interval, $state, helperService) {
      'ngInject';

      let delay = 5; //delay between slide in seconds

      $scope.openPage = function openPage(page) {
        $state.go('sgcontenteditorViewPage', {page_id: page.page_id, page_name: helperService.cleanString(page.name), pageInfo: page});
      };

      function _setup() {
        console.log($scope.pages);
        if ($scope.pages.length) {
          $scope.cindex = 0;

          if ($scope.interval) {
            $interval.cancel($scope.interval);
            $scope.interval = null;
          }

          $scope.interval = $interval(_moveNext, delay * 1000);
        }
        else {
          $scope.cindex = null;
        }
      }

      function _nextIndex() {
        let nextIndex = $scope.cindex + 1;
        return nextIndex >= $scope.pages.length ? 0 : nextIndex;
      }

      // function _prevIndex() {
      //   let prevIndex = $scope.cindex - 1;
      //   return prevIndex < 0 ? $scope.pages.length - 1 : prevIndex;
      // }

      function _moveNext() {
        $scope.moveToLeft = $scope.cindex;
        $scope.moveFromRight = $scope.cindex = _nextIndex();
      }

      // function _movePrev() {
      //   $scope.moveToRight = $scope.cindex;
      //   $scope.moveFromLeft = $scope.cindex = _prevIndex();
      // }

      $scope.$watch('pages', _setup);
    },
    template: require('./featuredRotate.html'),
    scope: {
      pages: "="
    }
  };
};
