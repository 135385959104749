'use strict';

import _ from 'lodash';

export default function sgcontenteditorService($q, $http, $uibModal, Upload) {
  'ngInject';

  //function _saveResponse(page_id, responses) {
  //  var data = {
  //    responses: responses
  //  };
  //  return $q.when($http.post('/api/pages/' + page_id + '/response', data))
  //    .then(function (result) {
  //      if (result.status === 200) {
  //        return { status: 'Success' };
  //      }

  //      return { status: 'Error', result: result };
  //    }, function (result) {
  //      return { status: 'Error', result: result };
  //    });
  //}

  //function _feature(page_id) {
  //  return $q.when($http.put('/api/featured?type=pages&page_id=' + page_id))
  //    .then(function (result) {
  //      if (result.status === 200) {
  //        return { status: 'Success' };
  //      }

  //      return { status: 'Error', result: result };
  //    }, function (result) {
  //      return { status: 'Error', result: result };
  //    });
  //}

  function _getPageIdFromScope($scope) {
    if($scope.page_id) {
      return $scope.page_id;
    }
    else if($scope.$parent) {
      return _getPageIdFromScope($scope.$parent);
    }
    else {
      return 0;
    }
  };

  function _delete(page_id) {
    return $q.when($http.delete('/api/pages/page?page_id=' + page_id))
      .then(function(result) {
        if(result.status === 200) {
          return { status: 'Success' };
        }

        return {
          status: result.status,
          errMsg: result.data
        };
      }, function(result) {
        return {
          status: result.status,
          errMsg: result.data
        };
      });
  }

  function _unpublish(page_id) {
    return $q.when($http.put('/api/pages/' + page_id + '/unpublish'))
      .then(function(result) {
        if(result.status === 200) {
          return { status: 'Success' };
        }

        return {
          status: result.status,
          errMsg: result.data
        };
      }, function(result) {
        return {
          status: result.status,
          errMsg: result.data
        };
      });
  }

  function _listFeaturedPages(limit, skip) {
    let params = { limit: limit, skip: skip, type: 'pages' };
    return $q
      .when($http({
        url: '/api/featured',
        method: 'GET',
        params: params
      }))
      .then(function(result) {
        if(result.status === 200) {
          return result.data;
        }

        return {
          status: result.status,
          errMsg: result.data
        };
      }, function(result) {
        return {
          status: result.status,
          errMsg: result.data
        };
      });
  }

  function _listMyPages(limit, skip, match) { //, subjects, grades, tags, provider
    let params = { limit: limit, skip: skip };
    if(match) {
      params.match = match;
    }
    //if (subjects && subjects.length > 0) {
    //  params.subject = subjects;
    //}
    //if (grades && grades.length > 0) {
    //  params.grade = grades;
    //}
    //if (tags) {
    //  params.tag = tags;
    //}
    //if (provider) {
    //  params.class = provider;
    //}
    return $q
      .when($http({
        url: '/api/pages/list',
        method: 'GET',
        params: params
      }))
      .then(function(result) {
        if(result.status === 200) {
          return result.data;
        }

        return {
          status: result.status,
          errMsg: result.data
        };
      }, function(result) {
        return {
          status: result.status,
          errMsg: result.data
        };
      });
  }

  function _listPages(limit, skip, match, sort) {
    let params = { limit: limit, skip: skip, count: 1, sort: sort };
    if(match) {
      params.match = match;
    }
    return $q
      .when($http({
        url: '/api/pages/listadmin',
        method: 'GET',
        params: params
      }))
      .then(function(result) {
        if(result.status === 200) {
          return result.data;
        }

        return {
          status: result.status,
          errMsg: result.data
        };
      }, function(result) {
        return {
          status: result.status,
          errMsg: result.data
        };
      });
  };

  function _newPage() {
    return {
      page_id: 0,
      Title: 'New Page',
      UrlName: 'NewPage',


      elements: [],
      files: [],
      images: [],
      //bannerImage: '',
      //bookCover: '',
      //grade: '',
      //subject: '',
      //topic: '',
      published: false,
    };
  };

  function _savePage(page, querystring) {
    var pageData = page;

    pageData.ElementsJson = JSON.stringify(page.elements);
    pageData.FilesJson = JSON.stringify(page.files);
      pageData.ImagesJson = JSON.stringify(page.images);
      console.log('json');
      var url = '/api/pages/page' + querystring;
      return $q.when($http({ method: 'post', url: url, data: pageData, headers: { 'Content-Type': 'application/json' } }))
      .then(function(result) {
        if(result.status === 200) {
          return _jsonToObject(result.data);
        }

        return {
          status: result.status,
          errMsg: result.data
        };
      }, function(result) {
        return {
          status: result.status,
          errMsg: result.data
        };
      });
  };

  function _getPageAdmin(page_id, timestamp) {
    timestamp = _.isUndefined(timestamp) ? "latest" : timestamp;
    return $q.when($http.get('/api/pages/page?page_id=' + page_id + '&timestamp=' + encodeURIComponent(timestamp)))
      .then(function(result) {
        if(result.status === 200) {
          return _jsonToObject(result.data);
        }

        return {
          status: result.status,
          errMsg: result.data
        };
      }, function(result) {
        return {
          status: result.status,
          errMsg: result.data
        };
      });
  };

  function _getPage(page_id) {
    return $q.when($http.get('/api/pages/page?page_id=' + page_id))
      .then(function(result) {
        if(result.status === 200) {
          return _jsonToObject(result.data);
        }

        return {
          status: result.status,
          errMsg: result.data
        };
      }, function(result) {
        return {
          status: result.status,
          errMsg: result.data
        };
      });
  };

  function _jsonToObject(page) {
    page.elements = JSON.parse(page.elementsJson || '[]');
    page.files = JSON.parse(page.filesJson || '[]');
    page.images = JSON.parse(page.imagesJson || '[]');
    return page;
  }
  
  function _generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  function _prompt(title, text, ok, cancel) {
    return $uibModal.open({
      template: require('./sgcontenteditorAdmin/popups/confirm.html'),
      controller: function($scope, $uibModalInstance) {
        $scope.modalOptions = {
          headerText: title,
          bodyText: text,
          actionButtonText: ok || 'Ok',
          closeButtonText: cancel || 'Cancel',
          showPrompt: true,
          promptText: '',
          ok: function() {
            $uibModalInstance.close($scope.modalOptions.promptText);
          },
          close: function() {
            $uibModalInstance.dismiss('cancel');
          }
        };
      }
    }).result;
  }

  function _confirm(title, text, ok, cancel) {
    return $uibModal.open({
      template: require('./sgcontenteditorAdmin/popups/confirm.html'),
      controller: function($scope, $uibModalInstance) {
        $scope.modalOptions = {
          headerText: title,
          bodyText: text,
          actionButtonText: ok || 'Ok',
          closeButtonText: cancel || 'Cancel',
          ok: function(result) {
            $uibModalInstance.close(result);
          },
          close: function() {
            $uibModalInstance.dismiss('cancel');
          }
        };
      }
    }).result;
  }

  function _alert(title, text, ok) {
    return $uibModal.open({
      template: require('./sgcontenteditorAdmin/popups/confirm.html'),
      controller: function($scope, $uibModalInstance) {
        $scope.modalOptions = {
          headerText: title,
          bodyText: text,
          actionButtonText: ok || 'Ok',
          closeButtonText: null,
          ok: function(result) {
            $uibModalInstance.close(result);
          }
        };
      }
    }).result;
  }

  //function _getTags() {
  //  return $q
  //    .when($http({
  //      url: '/api/keywords?type=grades&type=lesson-subjects',
  //      method: 'GET'
  //    }))
  //    .then(function (result) {
  //      if (result.status === 200) {
  //        return result.data;
  //      }

  //      return {
  //        status: result.status,
  //        errMsg: result.data
  //      };
  //    }, function (result) {
  //      return {
  //        status: result.status,
  //        errMsg: result.data
  //      };
  //    });

  //  // return $q(function(resolve) {
  //  //   resolve([
  //  //     { type: 'grades', tags: [ 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'] },
  //  //     { type: 'subjects', tags: ['Physical', 'Life', 'Earth-Space'] },
  //  //     { type: 'topics', tags: ['Engineering', '3DP']}
  //  //   ]);
  //  // });
  //}

  function _getCurrentUser() {
    return $q
      .when($http({
        url: '/api/user/getcurrent',
        method: 'GET'
      }))
      .then(function(result) {
        if(result.status === 200) {
          return result.data;
        }
        return {
          status: result.status,
          errMsg: result.data
        };
      }, function(result) {
        return {
          status: result.status,
          errMsg: result.data
        };
      });
  }

  function _uploadFile(file, bucket, statusUpdate) {
    return $q(function(resolve, reject) {
      Upload.upload({
        url: '/api/pages/fileupload',
        data: { file: file, bucket: bucket }
      }).then(function(resp) {
        resolve(resp.data);
      }, function(resp) {
        reject('Failed: ' + resp.status);
      }, function(evt) {
        statusUpdate(evt.loaded / evt.total);
      });
    });
  }

  return {
    //saveResponse: _saveResponse,
    getPageIdFromScope: _getPageIdFromScope,
    delete: _delete,
    unpublish: _unpublish,
    listFeaturedPages: _listFeaturedPages,
    listMyPages: _listMyPages,
    listPages: _listPages,
    newPage: _newPage,
    savePage: _savePage,
    getPage: _getPage,
    getPageAdmin: _getPageAdmin,
    generateUUID: _generateUUID,
    prompt: _prompt,
    confirm: _confirm,
    alert: _alert,
    //getTags: _getTags,
    getCurrentUser: _getCurrentUser,
    uploadFile: _uploadFile
    //feature: _feature
  };
}
