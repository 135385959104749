'use strict';

//import angular from 'angular';
//import uiRouter from 'angular-ui-router';

import routing from './routes';

import sgcontenteditorService from './service';

import sgcontenteditorViewPageCtrl from "./sgcontenteditorPage/viewPage.controller";
import sgcontenteditorListPagesCtrl from "./sgcontenteditorPage/listPages.controller";

import sgcontenteditorEditorListPagesCtrl from './sgcontenteditorAdmin/listPages.controller';
import sgcontenteditorEditorEditPageCtrl from './sgcontenteditorAdmin/editPage.controller';

import sgcontenteditorEditorElementListCtrl from './sgcontenteditorAdmin/popups/elementList';
import sgcontenteditorEditorFileBrowserCtrl from './sgcontenteditorAdmin/popups/fileBrowser';
import sgcontenteditorEditorVersioningCtrl from './sgcontenteditorAdmin/popups/versioning';

import sgcontenteditorEditorPageProperties from './sgcontenteditorAdmin/directives/pageProperties';
import sgcontenteditorEditorElementProperties from './sgcontenteditorAdmin/directives/elementProperties';
import sgcontenteditorEditorTreeview from './sgcontenteditorAdmin/directives/treeview';
import sgcontenteditorEditorTreeviewNode from './sgcontenteditorAdmin/directives/treeviewNode';
import sgcontenteditorEditorChildElements from './sgcontenteditorAdmin/directives/childElements';
import sgcontenteditorEditorElementsDisplay from './sgcontenteditorAdmin/directives/elementsDisplay';
import sgcontenteditorEditorElementRender from './sgcontenteditorAdmin/directives/elementRender';
import sgcontenteditorEditorFileUpload from './sgcontenteditorAdmin/directives/fileUpload';
import sgcontenteditorEditorFileUploadDrop from './sgcontenteditorAdmin/directives/fileUploadDrop';
import sgcontenteditorEditorPaneresize from './sgcontenteditorAdmin/directives/paneResize';
import ckeditor from './sgcontenteditorAdmin/directives/ckeditor';
import fileModel from './sgcontenteditorAdmin/directives/fileModel';
import contenteditable from './sgcontenteditorAdmin/directives/contenteditable';
import sgcontenteditorFeaturedRotate from './sgcontenteditorAdmin/directives/featuredRotate';

import sgcontenteditorElementGroupedtabs from './elements/groupedtabs';
import sgcontenteditorElementHtml from './elements/html';
import sgcontenteditorElementDhheader from './elements/dhheader';
import sgcontenteditorElementTitle from './elements/title';
import sgcontenteditorElementVideo from './elements/video';
import sgcontenteditorElementIframe from './elements/iframe';
import sgcontenteditorElementColumns from './elements/columns';
import sgcontenteditorElementSection from './elements/section';

import sgcontenteditorElementInteractivesCircuits from './elements/interactives/circuits';

import sgcontenteditorElementpropGroupedtabs from './elements/properties/groupedtabs';
import sgcontenteditorElementpropHtml from './elements/properties/html';
import sgcontenteditorElementpropDhheader from './elements/properties/dhheader';
import sgcontenteditorElementpropTitle from './elements/properties/title';
import sgcontenteditorElementpropSubtab from './elements/properties/subtab';
import sgcontenteditorElementpropTab from './elements/properties/tab';
import sgcontenteditorElementpropVideo from './elements/properties/video';
import sgcontenteditorElementpropIframe from './elements/properties/iframe';
import sgcontenteditorElementpropColumns from './elements/properties/columns';
import sgcontenteditorElementpropColumn from './elements/properties/column';
import sgcontenteditorElementpropSection from './elements/properties/section';





import sgcontenteditorFilterYoutubeEmbedUrl from './sgcontenteditorAdmin/filters/youtubeEmbedUrl';
import sgcontenteditorFilterIframeUrl from './sgcontenteditorAdmin/filters/iframeUrl';

export default angular.module('sgcontenteditor', [])
  .config(function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
      // Allow same origin resource loads.
      'self',
      'https://www.youtube.com/**',
      'https://player.vimeo.com/**'
    ]);
  })
  .config(routing)
  .factory('sgcontenteditorService', sgcontenteditorService)
  .controller('sgcontenteditorViewPageCtrl', sgcontenteditorViewPageCtrl)
  .controller('sgcontenteditorListPagesCtrl', sgcontenteditorListPagesCtrl)

  .controller('sgcontenteditorEditorListPagesCtrl', sgcontenteditorEditorListPagesCtrl)
  .controller('sgcontenteditorEditorEditPageCtrl', sgcontenteditorEditorEditPageCtrl)

  .controller('sgcontenteditorEditorElementListCtrl', sgcontenteditorEditorElementListCtrl)
  .controller('sgcontenteditorEditorFileBrowserCtrl', sgcontenteditorEditorFileBrowserCtrl)
  .controller('sgcontenteditorEditorVersioningCtrl', sgcontenteditorEditorVersioningCtrl)



  .directive('sgcontenteditorEditorPageProperties', sgcontenteditorEditorPageProperties)
  .directive('sgcontenteditorEditorElementProperties', sgcontenteditorEditorElementProperties)
  .directive('sgcontenteditorEditorTreeview', sgcontenteditorEditorTreeview)
  .directive('sgcontenteditorEditorTreeviewNode', sgcontenteditorEditorTreeviewNode)
  .directive('sgcontenteditorEditorChildElements', sgcontenteditorEditorChildElements)
  .directive('sgcontenteditorEditorElementsDisplay', sgcontenteditorEditorElementsDisplay)
  .directive('sgcontenteditorEditorElementRender', sgcontenteditorEditorElementRender)
  .directive('sgcontenteditorEditorFileUpload', sgcontenteditorEditorFileUpload)
  .directive('sgcontenteditorEditorFileUploadDrop', sgcontenteditorEditorFileUploadDrop)
  .directive('sgcontenteditorEditorPaneresize', sgcontenteditorEditorPaneresize)
  .directive('ckeditor', ckeditor)
  .directive('fileModel', fileModel)
  .directive('contenteditable', contenteditable)
  .directive('sgcontenteditorFeaturedRotate', sgcontenteditorFeaturedRotate)

  .directive('sgcontenteditorElementGroupedtabs', sgcontenteditorElementGroupedtabs)
  .directive('sgcontenteditorElementHtml', sgcontenteditorElementHtml)
  .directive('sgcontenteditorElementDhheader', sgcontenteditorElementDhheader)
  .directive('sgcontenteditorElementTitle', sgcontenteditorElementTitle)
  .directive('sgcontenteditorElementVideo', sgcontenteditorElementVideo)
  .directive('sgcontenteditorElementIframe', sgcontenteditorElementIframe)
  .directive('sgcontenteditorElementColumns', sgcontenteditorElementColumns)
  .directive('sgcontenteditorElementSection', sgcontenteditorElementSection)

  .directive('sgcontenteditorElementpropGroupedtabs', sgcontenteditorElementpropGroupedtabs)
  .directive('sgcontenteditorElementpropHtml', sgcontenteditorElementpropHtml)
  .directive('sgcontenteditorElementpropDhheader', sgcontenteditorElementpropDhheader)
  .directive('sgcontenteditorElementpropTitle', sgcontenteditorElementpropTitle)
  .directive('sgcontenteditorElementpropSubtab', sgcontenteditorElementpropSubtab)
  .directive('sgcontenteditorElementpropTab', sgcontenteditorElementpropTab)
  .directive('sgcontenteditorElementpropVideo', sgcontenteditorElementpropVideo)
  .directive('sgcontenteditorElementpropIframe', sgcontenteditorElementpropIframe)
  .directive('sgcontenteditorElementpropColumns', sgcontenteditorElementpropColumns)
  .directive('sgcontenteditorElementpropColumn', sgcontenteditorElementpropColumn)
  .directive('sgcontenteditorElementpropSection', sgcontenteditorElementpropSection)

  .filter('sgcontenteditorFilterYoutubeEmbedUrl', sgcontenteditorFilterYoutubeEmbedUrl)
  .filter('sgcontenteditorFilterIframeUrl', sgcontenteditorFilterIframeUrl)
  .filter('startFrom', function () {
    return function(input, start) {
      if(!input) {
        return null;
      }
      start = +start; //parse to int
      return input.slice(start);
    };
  }).directive('convertToNumber', function() {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        ngModel.$parsers.push(function(val) {
          return val != null ? parseInt(val, 10) : null;
        });
        ngModel.$formatters.push(function(val) {
          return val != null ? '' + val : null;
        });
      }
    };
  }).directive('bgImg', function() {
    return function(scope, element, attrs) {
      attrs.$observe('bgImg', function(value) {
        if(value) {
          
          element.css({
            'background-image': 'url(' + value + ')'
          });
        }
      });
     };
  })
  .name;
