'use strict';

import _ from 'lodash';

export default function () {
  return {
    controller: function ($scope) {
      'ngInject';
      $scope.items = [
        {
          type: 'Light', width: 1, height: 1,
          image: '/assets/images/InteractiveElements/Electronics/asher/light1.png',
          altImage: '/assets/images/InteractiveElements/Electronics/asher/light2.png'
        },
        {
          type: 'Battery', width: 2, height: 1,
          image: '/assets/images/InteractiveElements/Electronics/images/Battery.png'
        },
        {
          type: 'WireLeft', width: 1, height: 2,
          image: '/assets/images/InteractiveElements/Electronics/asher/wire-c-black.png'
        },
        {
          type: 'WireRight', width: 1, height: 2,
          image: '/assets/images/InteractiveElements/Electronics/asher/wire-c-back-red.png'
        },
        {
          type: 'WireStraight', width: 1, height: 1,
          image: '/assets/images/InteractiveElements/Electronics/asher/wire-red.png'
        },
        {
          type: 'WireStraight', width: 1, height: 1,
          image: '/assets/images/InteractiveElements/Electronics/asher/wire-black.png'
        },
        {
          type: 'Switch', width: 1, height: 1,
          image: '/assets/images/InteractiveElements/Electronics/images/SwitchOpen.png',
          altImage: '/assets/images/InteractiveElements/Electronics/images/SwitchClosed.png'
        },
      ];
      $scope.grid = [];
      for (let y = 0; y < 5; y++) {
        for (let x = 0; x < 5; x++) {
          $scope.grid.push({ x: x, y: y });
        }
      }

      $scope.hover = {
        x: -1,
        y: -1,
        x2: -1,
        y2: -1,
        width: 1,
        height: 1
      };

      $scope.checkEnter = function (cell, $dragData) {
        $scope.hover.x = cell.x;
        $scope.hover.y = cell.y;
        $scope.hover.x2 = $dragData.item.width === 2 ? cell.x + 1 : cell.x;
        $scope.hover.y2 = $dragData.item.height === 2 ? cell.y + 1 : cell.y;
      };

      $scope.leave = function (cell) {
        if ($scope.hover.x === cell.x && $scope.hover.y === cell.y) {
          $scope.hover.x = $scope.hover.y = $scope.hover.x2 = $scope.hover.y2 = -1;
        }
      };

      $scope.dropAccepts = function (cell, $dragData) {
        //Don't allow wide or tall at edges
        if ($dragData.item.width === 2 && cell.x === 4 || $dragData.item.height === 2 && cell.y === 4) {
          return false;
        }
        return true;
      };

      $scope.drop = function (cell, $dragData) {
        $scope.hover.x = $scope.hover.y = $scope.hover.x2 = $scope.hover.y2 = -1;
        cell.item = $dragData.item;
        cell.item.currentImage = cell.item.image;

        //Clear overlapping cells
        if (cell.item.height === 2) {
          clearCell(cell.x, cell.y + 1);
        }
        if (cell.item.width === 2) {
          clearCell(cell.x + 1, cell.y);
        }

        //If it was dragged from a previous cell clear it
        if ($dragData.x !== null) {
          $dragData.item = null;
        }

        checkPower();
      };

      function clearCell(x, y) {
        _.forEach($scope.grid, function (cell) {
          if (cell.x === x && cell.y === y) {
            cell.item = null;
          }
        });
      };

      function getCell(x, y) {
        return _.find($scope.grid, function (cell) {
          return cell.x === x && cell.y === y;
        });
      }

      $scope.cellClick = function(cell) {
        if (cell.item && cell.item.type === 'Switch') {
          cell.item.on = !cell.item.on;
          cell.item.currentImage = cell.item.on ? cell.item.altImage : cell.item.image;
          checkPower();
        }
      };

      $scope.deleteAccepts = function($dragData) {
        return $dragData.x !== null;
      };
      $scope.delete = function($dragData) {
        $dragData.item = null;
        $scope.deleteHover = false;
      };

      function checkPower() {
        let batteries = _.filter($scope.grid, function (cell) {
          return cell.item && cell.item.type === 'Battery';
        });
        _.forEach($scope.grid, function(cell) {
          if (cell.item && cell.item.type === 'Light') {
            cell.item.currentImage = cell.item.image;
          }
        });
        _.forEach(batteries, function (battery) {
          var target = { x: battery.x, y: battery.y };

          var next = { x: target.x + 2, y: target.y, from: "left" };

          var lights = [];

          var count = 0;

          while ((next.x != target.x || next.y != target.y) && count++ < 100) {
            var nextGrid = getCell(next.x, next.y);
            var fail = false;
            var top = false;
            if (!nextGrid || !nextGrid.item) {
              //Check to the left
              var left = getCell(next.x - 1, next.y);
              if (left && left.item && left.item.width === 2) {
                nextGrid = left;
              }
              //Check to the top
              var topItem = getCell(next.x, next.y - 1);
              if (topItem && topItem.item && topItem.item.height === 2) {
                nextGrid = topItem;
                top = true;
              }

              if (!nextGrid || !nextGrid.item) {
                fail = true;
              }
            }

            if (!fail) {
              switch (nextGrid.item.type) {
                case "Fan":
                case "Light":
                case "Buzzer":
                  lights.push(nextGrid);
                  switch (next.from) {
                    case "left":
                      next.x += 1;
                      break;
                    case "right":
                      next.x -= 1;
                      break;
                    default:
                      fail = true;
                  }
                  break;
                case "Battery":
                  switch (next.from) {
                    case "left":
                      next.x += 2;
                      break;
                    case "right":
                      next.x -= 2;
                      break;
                    default:
                      fail = true;
                  }
                  break;
                case "WireStraight":
                  switch (next.from) {
                    case "left":
                      next.x += 1;
                      break;
                    case "right":
                      next.x -= 1;
                      break;
                    default:
                      fail = true;
                  }
                  break;
                case "Switch":
                  if (!nextGrid.item.on) {
                    fail = true;
                  }
                  else {
                    switch (next.from) {
                      case "left":
                        next.x += 1;
                        break;
                      case "right":
                        next.x -= 1;
                        break;
                      default:
                        fail = true;
                    }
                  }
                  break;
                case "WireLeft":
                  switch (next.from) {
                    case "right":
                      if (top) {
                        next.y -= 1;
                      }
                      else {
                        next.y += 1;
                      }
                      next.x += 1;
                      next.from = "left";
                      break;
                    default:
                      fail = true;
                  }
                  break;
                case "WireRight":
                  switch (next.from) {
                    case "left":
                      if (top) {
                        next.y -= 1;
                      }
                      else {
                        next.y += 1;
                      }
                      next.x -= 1;
                      next.from = "right";
                      break;
                    default:
                      fail = true;
                  }
                  break;
              }
            }
            if (fail) {
              return;
            }
          }

          _.each(lights, function (light) {
            switch (light.item.type) {
              case "Light":
                light.item.currentImage = light.item.altImage;
                break;
            }
          });
        });
      }

    },
    template: require('./circuits.html')
  };
};
