'use strict';

import _ from 'lodash';

export default function ($uibModalInstance, pastVersions, currentVersionTimestamp, $scope) {
  'ngInject';
  var self = this;

  $scope.GetColor = function(letter) {
    if(!letter) { return;}
    switch(letter.toLowerCase()) {
      case 'as':
        return "#500000";
      case 'jo':
        return "#C8102E";
      case 'do':
        return "#f88f00";
      default:
        return "#" + hashCode(letter);
    }

    function hashCode(str) { // java String#hashCode
      var hash = 0;
      for(var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    }

    function intToRGB(i) {
      var c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

      return "00000".substring(0, 6 - c.length) + c;
    }
  };

  self.currentVersionTimestamp = currentVersionTimestamp;

  //Group by day
  self.pastVersionGroups = [];
  var group = null, prev = null;

  _.forEach(pastVersions, function(value) {
    if (group === null) {
      group = [value];
      prev = value;
      self.pastVersionGroups.push(group);
      return;
    }
    var prevDay = new Date(prev.created).setHours(0, 0, 0, 0);
    var curDay = new Date(value.created).setHours(0, 0, 0, 0);
    if (prevDay == curDay) {
      group.push(value);
      prev = value;
    }
    else {
      group = [value];
      prev = value;
      self.pastVersionGroups.push(group);

    }
  });
};
