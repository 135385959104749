'use strict';

export default function() {
  return {
    controller: function VideoController($scope, $uibModal) {
      'ngInject';

          var scope = $scope;

          console.log($scope);

      $scope.showPopup = function showPopup() {
        $uibModal.open({
          templateUrl: 'videoPopup',
          size: 'xlg',
          windowClass: 'videoPopup',
          controller: function($scope, $uibModalInstance) {
            $scope.element = scope.element;
          }
        });
      };
    },
    template: require('./video.html')
  };
};
