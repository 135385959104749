'use strict';

export default function () {
  return {
    controller: function ($scope) {
      'ngInject';
      $scope.SelectPage = function SelectPage() {
        $scope.$emit("ElementSelected", $scope.$parent.vm.page, null);
      };

      $scope.$on('Element.Selected', function (event, element) {
        if (element == $scope.$parent.vm.page) {
          $scope.SelectPage();
        }
      });

    },
    template: require('./treeview.html'),
    scope: {
      elements: "=",
      selectedElement: "="
    }
  };
};
