'use strict';

import _ from 'lodash';

export default function sgcontenteditorListPagesCtrl($scope, sgcontenteditorService, $state, $uibModal) {
  'ngInject';

  var self = this;

  _init();

  self.pages = [];
  self.featuredPages = [];
  //self.tags = {};

  self.grades = [];
  self.subjects = [];
  self.provider = '';
  self.search = '';
  self.page = 1;
  self.pageSize = 12;
  self.hasMorePages = false;

  self.CleanString = function(value) {
    value = value.replace(/\W/g, '_').toLowerCase();
    value = value.replace(/__/g, '_');
    return value;
  };


  function _init() {
    _getFeaturedPageList();
    $scope.$watchGroup(['vm.grades', 'vm.subjects', 'vm.provider', 'vm.search'], function() {
      //Reset to page 1 on search so that we get the total count and don't get stuck on a page that doesn't exist
      self.page = 1;
      _getPageList();
    });

    //sgcontenteditorService.getTags().then(function callbackSuccess(tags) {
    //  self.tags = tags;
    //}, function callbackFail() { });

    if ($state.params.error) {
      self.dialog = $uibModal.open({
        template: '<div class="modal-header"><h3>Error</h3></div><div class="modal-body">' + $state.params.error + '</div>'
      });
    }

    if ($state.params.noAccess) {
      self.dialog = $uibModal.open({
        templateUrl: 'noAccess.html',
        controllerAs: '$ctrl',
        size: 'lg',
        controller: function() {
          this.page = $state.params.page;
          this.Join = self.Join;
          this.GetProvider = self.GetProvider;
        }
      });
    }
  }

  function _getPageList(loadMore) {
    let limit = self.pageSize + 1;
    let skip = self.pageSize * (self.page - 1);

    sgcontenteditorService.listMyPages(limit, skip, self.search, self.subjects, self.grades, null, self.provider).then(function (result) {
      if (!result.errMsg) {
        self.hasMorePages = result.pages.length === self.pageSize + 1;
        if (loadMore) {
          self.pages = _.concat(self.pages, _.slice(result.pages, 0, self.pageSize));
        }
        else {
          self.pages = _.slice(result.pages, 0, self.pageSize);
        }
      }
      else {
        self.dialog = $uibModal.open({
          template: '<div class="modal-header"><h3>Error</h3></div><div class="modal-body">' + result.status + " " + result.errMsg + '</div>'
        });
      }
    });
  }

  function _getFeaturedPageList() {
    let limit = 5;
    let skip = 0;

    sgcontenteditorService.listFeaturedPages(limit, skip).then(function (result) {
      if (!result.errMsg) {
        self.featuredPages = result.pages;
      }
      else {
        self.dialog = $uibModal.open({
          template: '<div class="modal-header"><h3>Error</h3></div><div class="modal-body">' + result.status + " " + result.errMsg + '</div>'
        });
      }
    });
  }

  self.GetProvider = function(page) {
    switch (page.class) {
      case 'makersempire':
        return 'Provider: Maker\'s Empire';
      case 'sgcontenteditor':
        return 'Provider: sgcontenteditor';
    }
  };

  self.Join = function(list) {
    let filteredList = _.filter(list, function(o) {
      return o !== null && o !== '';
    });
    return filteredList.join(', ');
  };

  self.loadMorePages = function() {
    self.hasMorePages = false;
    self.page += 1;
    _getPageList(true);
  };
};
